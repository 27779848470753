


























import { mapGetters } from 'vuex';
import { TTabsItem } from '@/api/schema';
import VTabsHash from '@/components/VTabsHash.vue';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export enum EGamificationCenterTabsKeys {
  SIGN_UP_REWARD = 'sign-up-reward',
  WHEEL_OF_FORTUNE = 'wheel-of-fortune',
  JACKPOT = 'jackpot'
}

export default {
  name: 'CopyStakeGamificationCenterTabs',
  components: {
    VTabsHash,
    CopyStakeGamificationCenterSignUpRewardTab: (): any =>
      import(
        '@/views/CopyStake/CopyStakeGamificationCenter/SignUpReward/CopyStakeGamificationCenterSignUpRewardTab.vue'
      ),
    CopyStakeGamificationCenterWheelOfFortuneTab: (): any =>
      import(
        '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/CopyStakeGamificationCenterWheelOfFortuneTab.vue'
      ),
    CopyStakeGamificationCenterJackpotTab: (): any =>
      import(
        '@/views/CopyStake/CopyStakeGamificationCenter/Jackpot/CopyStakeGamificationCenterJackpotTab.vue'
      )
  },
  data(): any {
    return {
      currentTab: 0
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', [
      'isFeaturesLoading',
      'isAdminVisibleFeatureFlag'
    ]),
    tabs(): TTabsItem[] {
      return [
        {
          id: EGamificationCenterTabsKeys.SIGN_UP_REWARD,
          text: 'Sign-up reward',
          component: 'CopyStakeGamificationCenterSignUpRewardTab',
          visible: this.isAdminVisibleFeatureFlag(
            ECopyStakeFeatureFlag.GAMIFICATION_CENTER_SIGN_UP_REWARD
          )
        },
        {
          id: EGamificationCenterTabsKeys.WHEEL_OF_FORTUNE,
          text: 'Wheel of fortune',
          component: 'CopyStakeGamificationCenterWheelOfFortuneTab',
          visible: this.isAdminVisibleFeatureFlag(
            ECopyStakeFeatureFlag.GAMIFICATION_CENTER_WHEEL_OF_FORTUNE
          )
        },
        {
          id: EGamificationCenterTabsKeys.JACKPOT,
          text: 'Jackpot',
          component: 'CopyStakeGamificationCenterJackpotTab',
          visible: this.isAdminVisibleFeatureFlag(
            ECopyStakeFeatureFlag.GAMIFICATION_CENTER_JACKPOT
          )
        }
      ].filter(({ visible }) => visible);
    }
  }
};
