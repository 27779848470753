import { mapState, mapGetters } from 'vuex';
import router from '@/router';
import { ELoyaltyFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('FeatureFlags', [
      'isEnabledFeatureFlag',
      'isFeaturesLoading'
    ]),
    isFeatureVisible(): boolean {
      // needs to be overridden for a component where you need to check
      // (green switch) visible/disable for operator CopyStake feature flag
      // example: this.isAdminVisibleFeatureFlag(ECopyStakeFeatureFlag.WEB_STREAMERS);
      return true;
    },
    isCopyStakeEnable(): boolean {
      // check (blue switch) on/off loyalty CopyStake main feature flag
      // and skip for superadmin role
      return (
        this.isSuperAdmin ||
        this.isEnabledFeatureFlag(ELoyaltyFeatureFlag.COPY_STAKE)
      );
    }
  },
  watch: {
    isFeaturesLoading: {
      handler: 'checkFeatureFlagRedirectHandler',
      immediate: true
    }
  },
  methods: {
    checkFeatureFlagRedirectHandler(isLoading: boolean): void {
      if (isLoading || (this.isCopyStakeEnable && this.isFeatureVisible)) {
        return;
      }

      router.push(
        this.isSuperAdmin ? { name: 'account-operator-balance' } : '/'
      );
    }
  }
};
