



















import { mapGetters } from 'vuex';
import VTabsHash from '@/components/VTabsHash.vue';
import CopyStakeGamificationCenterTabsView from '@/views/CopyStake/CopyStakeGamificationCenter/CopyStakeGamificationCenterTabsView.vue';
import FeatureFlagRedirectMixin from '@/mixins/copy-stake/FeatureFlagRedirectMixin';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  name: 'CopyStakeGamificationCenterView',
  components: {
    VTabsHash,
    CopyStakeGamificationCenterTabsView
  },
  mixins: [FeatureFlagRedirectMixin],
  computed: {
    ...mapGetters('FeatureFlags', ['isAdminVisibleFeatureFlag']),
    isFeatureVisible(): boolean {
      return this.isAdminVisibleFeatureFlag(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER
      );
    }
  }
};
