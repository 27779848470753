import { render, staticRenderFns } from "./CopyStakeGamificationCenterView.vue?vue&type=template&id=63aefa42&scoped=true&lang=pug"
import script from "./CopyStakeGamificationCenterView.vue?vue&type=script&lang=ts"
export * from "./CopyStakeGamificationCenterView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63aefa42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRow})
